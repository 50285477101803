import { useDispatch, useSelector } from "react-redux";
import { initalFromUpdate } from "store/slices/StmateSlice";
import { addValueToObjectOfKey, findObjectOfKey } from "utils/Functions";
import DebounceInput from "./DebounceInput";
import { useEffect, useMemo, useState } from "react";
import { HiDotsVertical } from "react-icons/hi";
import { Popover } from "antd";
import { IoWarningOutline } from "react-icons/io5";
import { AiOutlineDelete } from "react-icons/ai";
import { FaPlus, FaRegSquarePlus } from "react-icons/fa6";

const AddIssueWithButton = ({ type, level }) => {
  const dispatch = useDispatch();
  const newdata = useSelector(({ STMate }) => STMate?.newdata ?? {});
  const [flag, setFlag] = useState(false);

  const currentTypeObj = useMemo(
    () => findObjectOfKey(newdata, type) ?? {},
    [newdata, type]
  );

  const removeIssue = (index) => {
    const result = currentTypeObj;
    if (Array.isArray(result?.label)) {
      // result?.label.splice(index, 1);
      let obj = { ...result.label[index] };
      obj["isActive"] = !obj?.isActive;
      result.label[index] = obj;
    }
    const finalObj = addValueToObjectOfKey({ ...newdata }, type, result);
    dispatch(initalFromUpdate(finalObj));
  };

  const removeHazardIssue = (index) => {
    const result = currentTypeObj;
    if (Array.isArray(result?.label)) {
      let obj = { ...result.label[index] };
      obj["isHazard"] = !obj?.isHazard;
      result.label[index] = obj;
    }
    const finalObj = addValueToObjectOfKey({ ...newdata }, type, result);
    dispatch(initalFromUpdate(finalObj));
  };

  const updateIssueValue = (val, index, isHazard) => {
    const result = currentTypeObj;
    if (Array.isArray(result?.label)) {
      result.label[index] = { val, isActive: true, isHazard };
    }
    const finalObj = addValueToObjectOfKey({ ...newdata }, type, result);
    dispatch(initalFromUpdate(finalObj));
  };

  useEffect(() => {
    if (level || level === 0) {
      const result = currentTypeObj;
      if (!Array.isArray(result?.label))
        result.label = [{ val: "", isActive: true, isHazard: false }];
      const finalObj = addValueToObjectOfKey({ ...newdata }, type, result);
      dispatch(initalFromUpdate(finalObj));
    }
  }, []);

  return (
    <div className="mb-3 grid gap-2">
      {currentTypeObj &&
        Array.isArray(currentTypeObj?.label) &&
        Array.from(currentTypeObj?.label ?? []).map((el, index) => (
          <div className="flex items-center" key={`${el?.val}-${index}`}>
            <DebounceInput
              issueVal={el}
              onValueChange={(val) =>
                updateIssueValue(val, index, el?.isHazard)
              }
              flag={flag}
              setFlag={setFlag}
            />
            {el?.isActive ? (
              <ThreeDotComponent
                issueVal={el}
                currentTypeObj={currentTypeObj}
                type={type}
                onRemove={() => removeIssue(index)}
                onHazard={() => removeHazardIssue(index)}
                index={index}
                setFlag={setFlag}
              />
            ) : (
              <span onClick={() => removeIssue(index)}>
                <FaRegSquarePlus
                  style={{
                    color: "#c1c1c1",
                    height: "30px",
                    width: "30px",
                    cursor: "pointer",
                  }}
                />
              </span>
            )}
          </div>
        ))}
    </div>
  );
};

export default AddIssueWithButton;

const ThreeDotComponent = ({
  issueVal,
  currentTypeObj,
  type,
  onRemove,
  onHazard,
  index,
  setFlag,
}) => {
  const dispatch = useDispatch();
  const [modalstat, setModalstat] = useState(false);
  const newdata = useSelector(({ STMate }) => STMate?.newdata ?? {});

  const showDots = () => {
    onRemove();
    setModalstat(false);
  };

  const handleVisibleChange = (el) => {
    setModalstat(!el);
  };

  const handleAddIssue = () => {
    const result = currentTypeObj;
    if (Array.isArray(result?.label)) {
      result.label.push({ val: "", isActive: true, isHazard: false });
    } else {
      result.label = [{ val: "", isActive: true, isHazard: false }];
    }
    const finalObj = addValueToObjectOfKey({ ...newdata }, type, result);
    dispatch(initalFromUpdate(finalObj));
    setFlag(false);
    setModalstat(false);
  };

  const content = () => {
    return (
      <div className="grid gap-2 w-[50px]">
        <div
          onClick={() => {
            onHazard(index);
            setModalstat(false);
          }}
          className="flex items-center gap-3 cursor-pointer"
        >
          <IoWarningOutline />
          <p style={{ whiteSpace: "nowrap" }}>
            {issueVal?.isHazard ? "Remove Hazard" : "Hazard"}
          </p>
        </div>
        <div
          className={`flex items-center gap-3 cursor-pointer`}
          onClick={showDots}
        >
          <AiOutlineDelete />
          <p>Delete</p>
        </div>
        <div
          className="cursor-pointer flex items-center gap-3"
          onClick={() => handleAddIssue(index)}
        >
          <FaPlus />
          <p style={{ whiteSpace: "nowrap" }}> Add New</p>
        </div>
      </div>
    );
  };

  return (
    <Popover
      placement="topRight"
      content={content}
      trigger={"click"}
      open={modalstat}
      overlayInnerStyle={{ width: "150px" }}
      prefixCls="over_right_class"
      arrow={false}
      onOpenChange={() => handleVisibleChange(modalstat)}
    >
      <div
        onClick={() => {
          setModalstat(!modalstat);
        }}
        className="w-12 h-12 text-2xl flex items-center justify-center cursor-pointer"
      >
        <HiDotsVertical className="cursor-pointer" />
      </div>
    </Popover>
  );
};
