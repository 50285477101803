import { Group } from "module/group/Group";
import { Jobs } from "module/jobs/Jobs";
import { Managers } from "module/managers/Managers";
import { Team } from "module/team/Team";
import { Users } from "module/users/Users";
import { Business } from "module/workspace/business/Business";
import { JobType } from "module/workspace/jobType/JobType";
import { Regions } from "module/workspace/regions/Regions";
import Stmate from "pages/stmate";
import Form from "pages/stmate/Form";
import IssuesTree from "module/settings/IssuesTree";

export const PrivatePaths = [
  {
    path: "jobs/:id?",
    component: <Stmate />,
  },
  {
    path: "job-chat",
    component: <Jobs />,
  },
  {
    path: "group",
    component: <Group />,
  },
  {
    path: "team",
    component: <Team />,
  },
  {
    path: "users",
    component: <Users />,
  },
  {
    path: "business",
    component: <Business />,
  },
  {
    path: "regions",
    component: <Regions />,
  },
  {
    path: "job-types",
    component: <JobType />,
  },
  {
    path: "stmate-form",
    component: <Form />,
  },
  {
    path: "managers",
    component: <Managers />,
  },
  {
    path: "drag-tree",
    component: <IssuesTree />,
  },
];
