export const groupByPriority = (services) => {
  const grouped = {};
  // Group services by priority
  services?.forEach((service) => {
    const priority = service?.urgency;
    if (!grouped[priority]) {
      grouped[priority] = [];
    }
    grouped[priority]?.push(service);
  });

  // Convert grouped object to the desired structure
  let r = Object?.entries(grouped)?.map(([priority, children]) => ({
    priority: parseInt(priority, 10),
    child: children,
  }));
  return r;
};

export function deepClone(obj) {
  return JSON.parse(JSON.stringify(obj));
}

export function insertValueByKey(obj, keyToInsert, valueToInsert) {
  // Base case: if obj is not an object or array, return the object as is
  if (typeof obj !== "object" || obj === null) {
    return obj;
  }

  // Create a deep copy of the object to avoid direct modification
  const clonedObj = deepClone(obj);

  // Check if the keyToInsert exists at the current level of the object
  if (keyToInsert in clonedObj) {
    const newKey = String(valueToInsert).trimEnd();

    // Access subItems safely
    const subItems = clonedObj[keyToInsert]["subItems"] || {};

    // Check if valueToInsert already exists in subItems
    if (newKey in subItems) {
      // Toggle the 'isActive' property of the existing item
      subItems[newKey].isActive = !subItems[newKey].isActive;
    } else {
      // Otherwise, add a new item with 'isActive: true' and an empty subItems
      subItems[newKey] = { isActive: true, subItems: {} };
    }

    // Safely update the subItems property in the cloned object
    clonedObj[keyToInsert]["subItems"] = subItems;

    return clonedObj;
  }

  // Recursively search nested objects to insert the value
  for (let key in clonedObj) {
    if (typeof clonedObj[key] === "object") {
      let updatedObj = insertValueByKey(
        clonedObj[key],
        keyToInsert,
        valueToInsert
      );
      // If the key was found and inserted, return the updated object
      if (updatedObj) {
        clonedObj[key] = updatedObj;
        return clonedObj;
      }
    }
  }

  // If the keyToInsert was not found, return null
  return null;
}

export function removeKeyValueFromObj(obj, keyToRemove) {
  const temp = deepClone(obj);
  return Object.keys(temp ?? {}).reduce((acc, e) => {
    if (e !== keyToRemove) {
      acc[e] = temp[e];
    } else {
      temp[e]["isActive"] = !temp[e]["isActive"];
      acc[e] = temp[e];
    }
    return acc;
  }, {});
}

export function findObjectOfKey(obj, keyToFind) {
  // Base case: if obj is not an object or array, return the object as is
  if (typeof obj !== "object" || obj === null) {
    return obj;
  }

  // Create a deep copy of the object to avoid direct modification of read-only properties
  const clonedObj = deepClone(obj);

  // If the key is found at the current level, update its value
  if (keyToFind in clonedObj) {
    return clonedObj[keyToFind];
  }

  // Recursively traverse and insert the value at the nested level where the key exists
  for (let key in clonedObj) {
    if (typeof clonedObj[key] === "object" || key === keyToFind) {
      const found = findObjectOfKey(clonedObj[key], keyToFind);
      if (found) return found;
    }
  }

  // If the key wasn't found, return the original cloned object without any changes
  return null;
}

export function addValueToObjectOfKey(obj, keyToFind, valueToInsert) {
  // Base case: if obj is not an object or array, return the object as is
  if (typeof obj !== "object" || obj === null) {
    return obj;
  }

  // Create a deep copy of the object to avoid direct modification of read-only properties
  const clonedObj = deepClone(obj);

  // If the key is found at the current level, update its value
  if (keyToFind in clonedObj) {
    clonedObj[keyToFind] = structuredClone(valueToInsert);
    return clonedObj;
  }

  // Recursively traverse and insert the value at the nested level where the key exists
  for (let key in clonedObj) {
    if (typeof clonedObj[key] === "object" || key === keyToFind) {
      const updatedObj = addValueToObjectOfKey(
        clonedObj[key],
        keyToFind,
        valueToInsert
      );
      if (updatedObj) {
        clonedObj[key] = updatedObj;
        return clonedObj;
      }
    }
  }

  // If the key wasn't found, return the original cloned object without any changes
  return null;
}

export function debounce(func, timeout = 300) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
}

export function uniqueArrayOfObjects(arr, key) {
  const seen = new Set();
  return arr.filter((obj) => {
    const value = obj[key];
    if (!seen.has(value)) {
      seen.add(value);
      return true;
    }
    return false;
  });
}

export function findDeepestLevel(obj) {
  let maxDepth = 0;

  function traverse(obj, depth) {
    if (typeof obj !== "object" || obj === null) {
      return;
    }

    maxDepth = Math.max(maxDepth, depth);

    for (const key in obj) {
      traverse(obj[key], depth + 1);
    }
  }

  traverse(obj, 1);
  return maxDepth;
}

export function deepEqual(obj1, obj2) {
  // Check if they are the same object reference
  if (obj1 === obj2) return true;

  // Check if they are both objects and not null
  if (
    typeof obj1 !== "object" ||
    obj1 === null ||
    typeof obj2 !== "object" ||
    obj2 === null
  ) {
    return false;
  }

  // Check if they have the same number of keys
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);
  if (keys1.length !== keys2.length) return false;

  // Compare each key-value pair recursively
  for (const key of keys1) {
    if (!deepEqual(obj1[key], obj2[key])) return false;
  }

  return true;
}

export function getKeysExceptIssue(obj) {
  let keys = [];

  // Traverse each key in the object
  for (const key in obj) {
    if (key !== "label") {
      keys.push(key);
      // If the value is an object, recursively collect keys
      if (typeof obj[key] === "object" && !Array.isArray(obj[key])) {
        keys = keys.concat(getKeysExceptIssue(obj[key]));
      }
    }
  }

  return keys;
}

export function toggleStringInArray(arr, str) {
  let newArr = [...arr]; // Spread operator to clone the array

  const index = newArr.indexOf(str);

  if (index !== -1) {
    // String exists in the array, remove it
    newArr.splice(index, 1);
  } else {
    // String does not exist, add it
    newArr.push(str);
  }

  return newArr;
}

export function getAllKeys(obj) {
  let keys = [];

  function recurse(currentObj) {
    for (let key in currentObj) {
      if (
        key !== "label" &&
        key !== "isActive" &&
        key !== "subItems" &&
        key !== "isExpend"
      ) {
        keys.push(key);
      }

      if (
        typeof currentObj[key] === "object" &&
        currentObj[key] !== null &&
        !Array.isArray(currentObj[key])
      ) {
        recurse(currentObj[key]);
      }
    }
  }

  recurse(obj);
  return keys;
}

export function removeIsActive(obj) {
  const result = {};

  for (const key in obj) {
    // Check if the property is the "label" array
    if (key === "label" && Array.isArray(obj[key])) {
      // Transform the "label" array to an array of "val" strings where "isActive" is true
      result[key] = obj[key]
        .filter((item) => item.isActive === true) // Only keep items where isActive is true
        .map((item) => item.val); // Map the result to an array of "val" strings
    } else if (typeof obj[key] === "object" && !Array.isArray(obj[key])) {
      // If "isActive" is false, remove the object
      if (obj[key]?.hasOwnProperty("isActive") && obj[key].isActive === false) {
        continue; // Skip this object entirely
      }
      // If "isActive" is true, keep it and process its nested objects
      else if (
        obj[key]?.hasOwnProperty("isActive") &&
        obj[key].isActive === true
      ) {
        result[key] = removeIsActive(obj[key]);
      }
      // If no "isActive" key, just process the object normally
      else {
        result[key] = removeIsActive(obj[key]);
      }
    } else {
      // If it's not an object or it's an array (excluding "label"), keep it as is
      result[key] = obj[key];
    }
  }

  return result;
}

export function convertToServiceString(input) {
  let lowerCaseString = input.toLowerCase();
  let hyphenatedString = lowerCaseString.replace(/\s+/g, "-");
  let resultString = "chk-" + hyphenatedString;

  return resultString;
}

export function toggleActiveStatus(data, name) {
  let obj = deepClone(data);
  for (let key in obj) {
    if (obj[key].hasOwnProperty("isActive") && key === name) {
      obj[key].isActive = !obj[key].isActive;
    }
  }
  return obj;
}

export function toggleExpendStatus(data, name) {
  let obj = deepClone(data);
  for (let key in obj) {
    if (obj[key].hasOwnProperty("isExpend") && key === name) {
      obj[key].isExpend = !obj[key].isExpend;
    }
  }
  return obj;
}

export function convertStringToObjectArray(inputString) {
  try {
    // Split the string into two parts: the summary and the JSON string
    const sections = inputString.split('{"services":');

    if (sections.length < 2) {
      throw new Error("Invalid format, 'services' section missing.");
    }

    // Extract and clean up the summary part
    let summary = sections[0].trim();

    // Handle line breaks by splitting the summary by \n\n and joining them into paragraphs
    const formattedSummary = summary.split("\n\n").join("\n");

    // Extract and clean up the JSON string part
    const jsonString = '{"services":' + sections[1].trim();

    // Parse the JSON string to get the services array
    const servicesObject = JSON.parse(jsonString);

    // The services array is already formatted as an array, no need to convert further
    const servicesArray = servicesObject.services;

    return {
      Summary: formattedSummary,
      RecommendedServices: servicesArray,
      AiResponse: true,
    };
  } catch (error) {
    // console.error("Error parsing input string:", error.message);
    return {
      Summary: inputString,
      RecommendedServices: [],
      AiResponse: false,
    };
  }
}

export function cleanObject(obj) {
  if (typeof obj !== "object" || obj === null) return obj;

  Object.keys(obj).forEach((key) => {
    const value = obj[key];

    // Recursively clean nested objects
    if (typeof value === "object" && value !== null) {
      cleanObject(value);
    }

    // Delete the key if it’s 'isActive', 'isExpend', or an empty value
    if (
      key === "isActive" || // Remove 'isActive' key
      key === "isExpend" || // Remove 'isExpend' key
      value === null || // Remove null values
      value === undefined || // Remove undefined values
      (typeof value === "object" && // Remove empty objects or arrays
        Object.keys(value).length === 0)
    ) {
      delete obj[key];
    }
  });

  return obj; // Return the modified object
}

export const findLastActive = (data) => {
  let lastActiveKey = null;
  for (const key in data) {
    if (data[key].isActive) {
      lastActiveKey = key;
    }
  }
  return lastActiveKey;
};

export function truncateJSON(jsonObj, maxLength = 100) {
  // Convert JSON object to string
  const jsonString = JSON.stringify(jsonObj);
  // Truncate the string to the specified length
  if (jsonString.length > maxLength) {
    return jsonString.substring(0, maxLength) + "...";
  }
  return jsonString;
}

export function truncateTo100Words(input) {
  const words = input.split(' '); 
  if (words.length > 100) {
      return words.slice(0, 100).join(' ') + '...'; 
  }
  return input; 
}
