import {
  PushHistoryToSt,
  SubmitSummary,
  UpdatePushTOSt,
} from "services/Collections";
import { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Loader } from "components/Loader";
import { styled } from "styled-components";
import Header from "./Header";
import { TestDiv } from "./SubHeader";
import { manageForm, updateTimeForGtm } from "store/slices/StmateSlice";
import { useDispatch } from "react-redux";
import { MdArrowBackIos, MdArrowForwardIos } from "react-icons/md";
import { pushBtnInActiveStyle, pushBtnActiveStyle } from "./staticData";
import { FiFilePlus, FiUploadCloud } from "react-icons/fi";
import { RiDeleteBinLine } from "react-icons/ri";
import ReWriteSummary from "./ReWriteSummary";
import { DeleteModal } from "components/DeleteModal";
import { truncateTo100Words } from "utils/Functions";

export default function Form() {
  const dispatch = useDispatch();
  const stmateData = useSelector((state) => state?.STMate);
  const loginData = useSelector((state) => state?.LoginSlice?.data);
  const currentActiveJob = useSelector((state) => state?.STMate?.outsideJob);
  const headerData = useSelector((state) => state?.STMate?.header);
  const { formData, timeForGtm } = stmateData;
  const workspace = loginData?.workspaces?.[0];

  const textAreaRef = useRef(null);
  const summaryTitleRef = useRef(null);

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [pushLoader, setPushLoader] = useState(false);
  const [name, setName] = useState("");
  const [textField, setTextFiled] = useState("");
  const [header, setHeader] = useState(false);
  const [summaryTitle, setSummatyTitle] = useState("");
  const [versionIndex, setVersionIndex] = useState(0);
  const [openRewrite, setOpenRewrite] = useState(false);
  const [deleteSummary, setDeleteSummary] = useState(false);

  const handleDeleteSummary = () => {
    dispatch(
      manageForm(
        formData?.filter((ele, index) => index !== versionIndex && ele)
      )
    );
    toast.success("Variation deleted successfully");
    setDeleteSummary(false);
    const updateFormData = [...formData];
    setVersionIndex((pre) => pre - 1);
    let ActiveSummary = updateFormData?.[versionIndex - 1];
    setName("");
    setData(ActiveSummary);
    setSummatyTitle(ActiveSummary?.title);
    setTextFiled(ActiveSummary?.Summary);
  };

  const handleReWriteClose = () => {
    setOpenRewrite(false);
    setName("");
  };

  const AutoSelectTitle = () => {
    if (summaryTitleRef.current) {
      summaryTitleRef.current.select();
    }
  };

  const handleReWriteSummary = async () => {
    if (name == "" || name == undefined || name?.trim()?.length == 0) {
      toast.error("Please Add Instructions");
      return;
    }
    if (
      textField == "" ||
      textField == undefined ||
      textField?.trim()?.length == 0
    ) {
      toast.error("Please Enter Summary");
      return;
    }
    setLoading(true);
    const dataObj = {
      job: currentActiveJob,
      summary: textField,
      prompt: name,
    };

    const formValue = truncateTo100Words(name);

    if (window.dataLayer) {
      window.dataLayer.push({
        event: "form_rewrite_submission",
        form_value: formValue,
        Job_id: currentActiveJob,
        refine_id: timeForGtm,
      });
    }

    const res = await SubmitSummary(workspace?.key, dataObj);
    console.log("Instruction GPT Response: ", res);
    if (res?.status == 200) {
      let apiData = res?.data?.ai_response
        ?.split("***")
        ?.filter((ele) => ele?.trim()?.length > 1 && ele);
      const aiTitle =
        (apiData?.length > 1 && apiData?.[apiData?.length - 1]) || "";
      let newSummary = {
        Summary: apiData?.[0],
        oldSummary: apiData?.[0],
        RecommendedServices: [],
        buttonStatus: "Push to Service Titan",
        title: aiTitle,
        oldTitle: aiTitle,
      };
      const formValueRes = truncateTo100Words(apiData?.[0]);
      if (window.dataLayer) {
        window.dataLayer.push({
          event: "form_rewrite_reponse",
          form_value: formValueRes,
          Job_id: currentActiveJob,
          refine_id: timeForGtm,
        });
      }
      const updateFormData = [...formData, newSummary];
      dispatch(manageForm(updateFormData));
      setName("");
      setTextFiled(apiData?.[0]);
      setVersionIndex(formData?.length);
      setSummatyTitle(aiTitle);
      setData(newSummary);

      toast.success("Summary Re-write Successfully.", {
        style: {
          color: "black",
        },
      });
      setOpenRewrite(false);
    } else {
      toast.error(
        res?.response?.data?.message || res?.message || "Something went wrong"
      );
      setOpenRewrite(false);
    }
    setLoading(false);
  };

  const handlePushToSt = async (text) => {
    if (
      textField == "" ||
      textField == undefined ||
      textField?.trim()?.length == 0
    ) {
      toast.error("Please Enter Summary");
      return;
    }
    if (
      summaryTitle == "" ||
      summaryTitle == undefined ||
      summaryTitle?.trim()?.length == 0
    ) {
      toast.error("Please Enter Title");
      return;
    }
    setPushLoader(true);
    let res;
    if (text == "Update Service Titan") {
      let obj = {
        title: summaryTitle,
        summary: textField,
      };
      res = await UpdatePushTOSt(
        workspace?.key,
        formData[versionIndex]?.id,
        obj
      );
    } else {
      let obj = {
        user_id: loginData?.id,
        job_id: currentActiveJob,
        title: summaryTitle,
        summary: textField,
      };
      res = await PushHistoryToSt(workspace?.key, obj);
    }

    if (res?.status == 200 || res?.status == 201) {
      const cloneFormData = [...formData];
      let updateData = {
        oldSummary: textField,
        oldTitle: summaryTitle,
        buttonStatus: "Pushed to Service Titan",
        id: res?.data?.id,
      };
      let updateFormData = cloneFormData?.map((ele, i) =>
        i == versionIndex ? { ...ele, ...updateData } : ele
      );
      dispatch(manageForm(updateFormData));
      setData((pre) => ({ ...pre, ...updateData }));

      let toastMessage = text == "Update Service Titan" ? "Updated" : "Push";
      toast.success(
        `${toastMessage || "Push"} to Service Titan Successfully.`,
        {
          style: {
            color: "black",
          },
        }
      );
    } else {
      toast.error(
        res?.response?.data?.message || res?.message || "Something went wrong"
      );
    }
    setPushLoader(false);
  };

  const handleVersionSummary = (index) => {
    const updateFormData = [...formData];
    let ActiveSummary = updateFormData?.[index];
    setName("");
    setData(ActiveSummary);
    setSummatyTitle(ActiveSummary?.title);
    setTextFiled(ActiveSummary?.Summary);
  };

  const updateTitle = (value) => {
    setSummatyTitle(value);

    let formObj = formData?.[versionIndex];
    let buttonText = "Push to Service Titan";
    if (formObj?.id) {
      if (formObj?.oldTitle?.trim() == value?.trim()) {
        buttonText = "Pushed to Service Titan";
      } else {
        buttonText = "Update Service Titan";
      }
    }

    const cloneFormData = [...formData];
    let updateFormData = cloneFormData?.map((ele, i) =>
      i == versionIndex
        ? { ...ele, buttonStatus: buttonText, title: value }
        : ele
    );
    dispatch(manageForm(updateFormData));
    setData((pre) => ({
      ...pre,
      buttonStatus: buttonText,
      title: value,
    }));
  };

  const updateSummary = (value) => {
    setTextFiled(value);

    let formObj = formData?.[versionIndex];
    let buttonText = "Push to Service Titan";
    if (formObj?.id) {
      if (formObj?.oldTitle?.trim() == value?.trim()) {
        buttonText = "Pushed to Service Titan";
      } else {
        buttonText = "Update Service Titan";
      }
    }

    const cloneFormData = [...formData];
    let updateFormData = cloneFormData?.map((ele, i) =>
      i == versionIndex
        ? { ...ele, buttonStatus: buttonText, Summary: value }
        : ele
    );
    dispatch(manageForm(updateFormData));
    setData((pre) => ({
      ...pre,
      buttonStatus: buttonText,
      Summary: value,
    }));
  };

  useEffect(() => {
    setData(formData?.[versionIndex] || {});
    setName("");
    setTextFiled(formData?.[versionIndex]?.Summary || "");
    setSummatyTitle(formData?.[versionIndex]?.title || "");
    setVersionIndex(0);
  }, []);

  const handlePushBtn = () => {
    let formObj = formData?.[versionIndex];
    let buttonText = "Push to Service Titan";
    if (formObj?.id) {
      if (
        formObj?.oldTitle?.trim() == summaryTitle?.trim() &&
        formObj?.oldSummary?.trim() == textField?.trim()
      ) {
        buttonText = "Pushed to Service Titan";
      } else {
        buttonText = "Update Service Titan";
      }
    }
    return buttonText;
  };

  const PushToStStauts = useMemo(
    () => handlePushBtn(),
    [textField, summaryTitle, formData[versionIndex]]
  );

  if (loading || data == null) {
    return (
      <div className="w-full h-full flex justify-center items-center">
        <Loader size={34} color={"#000"} />
      </div>
    );
  }

  const handleRewrite = () => {
    const secondsSinceEpoch = Math.floor(Date.now() / 1000);
    dispatch(updateTimeForGtm(secondsSinceEpoch));
    setOpenRewrite(true);
  };

  return (
    <FormWrapper>
      <TestDiv>
        <Header
          jobDetails={headerData?.data}
          header={header}
          setHeader={setHeader}
        />
        <div className="w-full pt-2 bg-[#d9d9d9] flex justify-between items-center pl-[15px]">
          <ButtonStyle
            active={true}
            btnStyle={{ margin: "0 0 8px 0" }}
            onClick={() => handleRewrite()}
          >
            <FiFilePlus size={"22px"} /> Create Variation
          </ButtonStyle>

          <div className="veriationSection">
            <VersionStyleTab>
              <button
                onClick={() =>
                  versionIndex != 0 &&
                  (setVersionIndex((pre) => pre - 1),
                  handleVersionSummary(versionIndex - 1))
                }
                style={{
                  background: versionIndex !== 0 ? "#d0cdcd" : "#f6f6f6",
                  padding: "0 8px",
                }}
              >
                {versionIndex !== 0 && (
                  <MdArrowBackIos
                    color="black"
                    style={{
                      cursor: versionIndex == 0 ? "default" : "pointer",
                      marginLeft: "4px",
                    }}
                  />
                )}
              </button>

              <div className="summaryTitle">
                <span>Summary {versionIndex + 1} </span>
                of {formData?.length}
              </div>

              <button
                onClick={() =>
                  versionIndex != formData?.length - 1 &&
                  (setVersionIndex((pre) => pre + 1),
                  handleVersionSummary(versionIndex + 1))
                }
                style={{
                  background:
                    versionIndex !== formData?.length - 1
                      ? "#d0cdcd"
                      : "#f6f6f6",
                }}
              >
                {versionIndex !== formData?.length - 1 && (
                  <MdArrowForwardIos
                    color="black"
                    style={{
                      cursor:
                        versionIndex == formData?.length - 1
                          ? "default"
                          : "pointer",
                    }}
                  />
                )}
              </button>
            </VersionStyleTab>

            <ButtonStyle
              // active={PushToStStauts == "Pushed to Service Titan" ? false : true}
              disabled={
                PushToStStauts == "Pushed to Service Titan" ? true : false
              }
              btnStyle={
                PushToStStauts == "Pushed to Service Titan"
                  ? pushBtnInActiveStyle
                  : pushBtnActiveStyle
              }
              onClick={() => handlePushToSt(PushToStStauts)}
            >
              {pushLoader ? (
                <>
                  <Loader color="#fff" size="20px" /> Pushing to Service Titan
                </>
              ) : (
                <>
                  <FiUploadCloud size={"22px"} /> {PushToStStauts}
                </>
              )}
            </ButtonStyle>
            {formData?.length > 1 && (
              <i className="deleteIcon" onClick={() => setDeleteSummary(true)}>
                <RiDeleteBinLine size={"25px"} />
              </i>
            )}
          </div>
        </div>
      </TestDiv>
      <div className="w-full mx-auto px-4 bg-[#f6f6f6] rounded-lg">
        <div className="flex gap-6">
          <div className="w-full col-span-2 mt-5 mx-3 flex flex-col">
            <div className="rounded-[20px] mb-4">
              <div className="flex items-starte gap-3 mb-3">
                <p className="text-base font-semibold">Title</p>
                <TextAreaStyle
                  ref={summaryTitleRef}
                  onFocus={AutoSelectTitle}
                  style={{ padding: "10px" }}
                  height="46px"
                  placeholder="Enter title"
                  value={summaryTitle}
                  onChange={(e) => updateTitle(e.target.value)}
                />
              </div>
              <TextAreaStyle
                // height={`calc(100vh - ${348 + (inputHeight || 50)}px)`}
                height={`calc(100vh - 264px)`}
                ref={textAreaRef}
                placeholder="Enter Summary"
                value={textField}
                onChange={(e) => updateSummary(e.target.value)}
              />
            </div>
          </div>

          {openRewrite && (
            <ReWriteSummary
              name={name}
              setName={setName}
              open={openRewrite}
              close={handleReWriteClose}
              submit={handleReWriteSummary}
            />
          )}

          {deleteSummary && (
            <DeleteModal
              handleClose={() => setDeleteSummary(false)}
              open={deleteSummary}
              title="Are you sure you want to delete this variation?"
              button="Delete"
              btnColor="#012e61"
              handleSubmit={handleDeleteSummary}
            />
          )}
        </div>
      </div>
    </FormWrapper>
  );
}

const FormWrapper = styled.div`
  .veriationSection {
    display: flex;
    align-items: center;
    gap: 15px;
    background: #f6f6f6;
    padding: 8px 15px 8px 10px;
    border-top-left-radius: 20px;
  }
  .deleteIcon {
    cursor: pointer;
  }
`;

export const ButtonStyle = styled.button`
  background: ${({ btnStyle, active }) =>
    active ? "#3a7bc2" : btnStyle?.background};
  color: ${({ btnStyle, active }) => (active ? "#fff" : btnStyle?.color)};
  padding: ${({ btnStyle }) => btnStyle?.padding || "10px 20px"};
  margin: ${({ btnStyle }) => btnStyle?.margin || "0"};
  border-radius: ${({ btnStyle }) => btnStyle?.borderRadius || "15px"};
  border: ${({ btnStyle }) => btnStyle?.border || "1px solid transparent"};
  font-weight: 600;
  cursor: ${({ btnStyle, active }) => (active ? "pointer" : btnStyle?.cursor)};
  white-space: nowrap;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${({ btnStyle }) => btnStyle?.gap || "8px"};
`;

const TextAreaStyle = styled.textarea`
  width: 100%;
  height: ${({ height }) => height || "calc(100vh - 380px)"};
  resize: none;
  padding: 15px 20px;
  border: 1px solid #c3c5c9;
  border-radius: 8px;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #a0a4ab;
    border-radius: 8px;
    border: 2px solid #f1f1f1;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #8e9299;
  }

  &:focus {
    outline: none;
  }
`;

const VersionStyleTab = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  font-size: 16px;
  color: #7d7d7d;

  button {
    width: 60px;
    height: 30px;
    display: flex;
    align-items: center;
    padding: 0 10px;
    justify-content: center;
    background: transparent;
    border: none;
    cursor: default;

    background: #d0cdcd;
    border-radius: 100%;
    height: 32px;
    width: 32px;
  }

  .summaryTitle {
    font-weight: 500;
    span {
      color: #000;
      font-size: 24px;
    }
  }
`;
