import { Modal } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { styled } from "styled-components";
import { FaPlus } from "react-icons/fa6";
import { DndContext } from "@dnd-kit/core";
import {
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { SectionTab } from "./SectionTab";

const ReWriteSummary = ({ name, setName, open, close, submit }) => {
  const inputPackageNameRef = useRef(null);
  const [sections, setSections] = useState([
    {
      id: 1,
      title: "Replace furnace",
      isActive: true,
      isOpen: false,
      description: "",
    },
    {
      id: 2,
      title: "Clean duckwork",
      isActive: true,
      isOpen: false,
      description: "",
    },
    {
      id: 3,
      title: "New thermostat",
      isActive: true,
      isOpen: false,
      description: "",
    },
  ]);

  const adjustHeight = () => {
    const textarea = inputPackageNameRef.current;
    if (textarea) {
      textarea.style.height = "auto";
      textarea.style.height = `${Math.min(textarea?.scrollHeight, 100)}px`;

      inputPackageNameRef?.current?.focus();
    }
  };

  const handleInput = (event) => {
    setName(event.target.value);
    adjustHeight();
  };

  useEffect(() => {
    adjustHeight();
  }, [name]);

  const handleAdd = () => {
    let obj = {
      title: "",
      isActive: true,
      isOpen: false,
      id: sections?.length + 1,
    };
    setSections((pre) => [...pre, obj]);
  };

  const handleToggle = (index, value) => {
    setSections((pre) =>
      pre?.map((el, idx) =>
        index == idx ? { ...el, [value]: !el?.[value] } : el
      )
    );
  };

  const handleSectionInput = (value, index) => {
    setSections((pre) =>
      pre?.map((el, idx) => (index == idx ? { ...el, description: value } : el))
    );
  };

  const handleDragEnd = ({ active, over }) => {
    if (active && over && active?.id !== over?.id) {
      const oldIndex = sections.findIndex(
        (section) => section?.id === active?.id
      );
      const newIndex = sections.findIndex(
        (section) => section?.id === over?.id
      );

      const updatedSections = [...sections];
      let data = updatedSections.splice(oldIndex, 1);
      updatedSections.splice(newIndex, 0, ...data);

      setSections(updatedSections);
    }
  };

  return (
    <Modal
      style={{ top: "30px" }}
      width={"700px"}
      prefixCls="reWrite"
      open={open}
      title="Create a Variation"
      footer={false}
      onCancel={close}
    >
      <ReWriteSummaryStyle>
        <p>
          Add instructions for STMate to change, or add, or remove parts of this
          summary and create a new one.
        </p>
        <div className="inputBorder">
          <InputTextAreaStyle
            ref={inputPackageNameRef}
            placeholder="Add instructions"
            type="text"
            value={name}
            rows={1}
            onInput={handleInput}
            style={{
              overflow: "auto",
              resize: "none",
              width: "100%",
              maxHeight: "100px",
            }}
          />
        </div>
        {/* <p className="sectionHeading">
          Section <span>Add, remove, reorder and update sections</span>
        </p> */}

        {/* <DndContext onDragEnd={handleDragEnd}>
          <SortableContext
            items={sections?.map((section) => section?.id)}
            strategy={verticalListSortingStrategy}
          >
            <div className="sectionTabWrap">
              {sections?.map((ele, index) => (
                <SectionTab
                  key={ele?.id}
                  ele={ele}
                  index={index}
                  handleToggle={handleToggle}
                  handleSectionInput={handleSectionInput}
                />
              ))}
            </div>
          </SortableContext>
        </DndContext> */}

        {/* <div className="add" onClick={handleAdd}>
          <i>
            <FaPlus size={"18px"} />
          </i>
          <span>Add Section</span>
        </div> */}
        <div className="footer">
          <button onClick={close}>Cancel</button>
          <button
            style={{ color: name?.trim()?.length > 0 ? "#000" : "#9d9b9b" }}
            onClick={submit}
          >
            Submit
          </button>
        </div>
      </ReWriteSummaryStyle>
    </Modal>
  );
};

export default ReWriteSummary;

const ReWriteSummaryStyle = styled.div`
  .inputBorder {
    border: 1px solid gray;
    width: 100%;
    height: fit-content;
    border-radius: 8px;
    margin: 20px 0;
    padding: 0;
  }

  .sectionHeading {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 10px;
    span {
      font-size: 12px;
      font-weight: 500;
      margin-left: 10px;
    }
  }

  .footer {
    margin-top: 25px;
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 20px;
    button {
      padding: 10px 20px;
      background: #e9e6e6;
      border-radius: 15px;
      box-shadow: 0px 2px 2px #c0bebe;
      color: black;
      font-weight: 600;
    }
  }

  .sectionTabWrap {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 10px 0;
    max-height: 250px;
    min-height: auto;
    overflow: auto;

    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1;
      border-radius: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #a0a4ab;
      border-radius: 8px;
      border: 2px solid #f1f1f1;
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: #8e9299;
    }

    &:focus {
      outline: none;
    }
  }

  .add {
    display: flex;
    align-items: center;
    background: #efefef;
    padding: 10px;
    border-radius: 5px;
    gap: 10px;
    box-shadow: 1px 2px 5px #d0d0d0;
    margin-top: 20px;
    cursor: pointer;

    span {
      font-weight: 600;
    }
  }
`;

const InputTextAreaStyle = styled.textarea`
  width: 100%;
  /* height: 95px; */
  resize: none;
  padding: 10px 20px;
  border-radius: 8px;
  margin: 0;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #a0a4ab;
    border-radius: 8px;
    border: 2px solid #f1f1f1;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #8e9299;
  }

  &:focus {
    outline: none;
  }
`;
