import { debounce } from "utils/Functions";
import { useState, memo, useCallback, useEffect, useRef } from "react";
import { IoWarningOutline } from "react-icons/io5";

const DebounceInput = ({ issueVal, onValueChange, flag, setFlag }) => {
  const [value, setValue] = useState(issueVal?.val ?? "");
  const inputRef = useRef(null);
  const updateParent = useCallback(debounce(onValueChange, 0), [onValueChange]);

  useEffect(() => {
    if (inputRef.current && !flag) {
      inputRef.current.focus();
      inputRef.current.selectionStart = inputRef.current.value.length;
      inputRef.current.selectionEnd = inputRef.current.value.length;
    }
  }, []);

  const handleBlur = () => {
    inputRef.current = null;
    setFlag(true);
    updateParent(value);
  };

  return (
    <div className="flex w-full overflow-y-auto rounded-[8px] outline-none gap-3 items-center">
      {issueVal?.isHazard && <IoWarningOutline />}
      <div className="flex flex-col w-full items-end">
        <textarea
          ref={inputRef}
          disabled={!issueVal?.isActive}
          type="text"
          placeholder="Describe the Issue/Solution/Recommendation"
          className={`w-full overflow-y-auto px-3 pt-1 rounded-[8px] outline-none resize-none border border-[#adabab] ${
            issueVal?.isActive
              ? "h-[90px] text-black"
              : "max-h-[90px] text-[#6c6c6c] bg-[#e3e3e3]"
          }`}
          value={value}
          onChange={(e) => setValue(e.target.value)}
          onBlur={handleBlur}
        />
      </div>
    </div>
  );
};

export default memo(DebounceInput);
