const pushBtnActiveStyle = {
  background: "#26201d",
  color: "#ffffff",
  padding: "7px 20px",
};

const pushBtnInActiveStyle = {
  color: "#cccccc",
  background: "#ffffff",
  padding: "7px 20px",
};

export { pushBtnActiveStyle ,pushBtnInActiveStyle};
