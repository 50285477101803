import React, { memo, useEffect, useState } from "react";
import { getCategories } from "services/Collections";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  addClientIssue,
  initalFromUpdate,
  updateClientIssue,
  updateExpendedItems,
} from "store/slices/StmateSlice";
import { Loader } from "components/Loader";
import {
  findLastActive,
  insertValueByKey,
  removeKeyValueFromObj,
} from "utils/Functions";
import SubIssuesComp, { IssueButton } from "components/SubIssuesComp";
import AddIssueWithButton from "components/AddIssueWithButton";
import {
  MdKeyboardDoubleArrowDown,
  MdKeyboardDoubleArrowUp,
} from "react-icons/md";

export const ExpendCloseBtn = ({
  setExpendService,
  expendService,
  text,
  isActive,
}) => {
  return (
    <div
      onClick={() => setExpendService(!expendService)}
      className={`w-full h-[40px] rounded-[8px] bg-[#d9d9d9] flex justify-center items-center gap-2 cursor-pointer my-5 ${
        isActive && "hidden"
      }`}
    >
      {expendService ? (
        <MdKeyboardDoubleArrowUp style={{ width: "20px", height: "20px" }} />
      ) : (
        <MdKeyboardDoubleArrowDown style={{ width: "20px", height: "20px" }} />
      )}
      <span>{text}</span>
    </div>
  );
};

// Define a simple button component
const HvacSalesIssues = ({ type, parentId }) => {
  const dispatch = useDispatch();

  const workspace = useSelector(
    (state) => state?.LoginSlice?.data?.workspaces?.[0]
  );
  const stmateData = useSelector((state) => state?.STMate);
  const { clientsIssues, parentCategory, newdata, expendedItems } = stmateData;

  const [expendService, setExpendService] = useState(false);
  const [loading, setLoading] = useState(true);

  const handleIssueClick = (issue, activeStatus) => {
    const foundKey = Object.keys(newdata[type]?.subItems ?? {}).find(
      (e) => e === issue.name
    );
    if (foundKey) {
      const tempData = structuredClone(newdata);
      tempData[type]["subItems"] = removeKeyValueFromObj(
        tempData[type]?.subItems,
        issue.name
      );
      dispatch(initalFromUpdate(tempData));
      if (activeStatus) {
        let issueName = findLastActive(tempData[type]?.subItems);
        dispatch(updateExpendedItems([issueName]));
        return;
      }
    } else {
      const result = insertValueByKey({ ...newdata }, type, issue.name);
      dispatch(initalFromUpdate(result));
    }
    dispatch(updateExpendedItems([issue.name]));
  };

  const handleSubCategories = async (type, id, onRemoveKey) => {
    if (id?.length > 0) {
      let params = new URLSearchParams();
      id?.map((el) => el && params.append("parent", el));
      const res = await getCategories(workspace?.key, params?.toString());
      if (res?.status === 200) {
        dispatch(updateClientIssue([...(res?.data ?? [])]));
        if (res?.data?.length === 0 && onRemoveKey) {
          onRemoveKey();
        }
      } else {
        toast.error(
          res?.response?.data?.message || res?.message || "Something went wrong"
        );
      }
    }
  };

  const handleCategories = async () => {
    setLoading(true);
    let params = new URLSearchParams();
    let id = parentId;
    params.append("parent", id);
    const res = await getCategories(workspace?.key, params?.toString());
    if (res?.status === 200) {
      dispatch(addClientIssue(res?.data));
      setLoading(false);
    } else {
      toast.error(
        res?.response?.data?.message || res?.message || "Something went wrong"
      );
      setLoading(false);
    }
  };

  useEffect(() => {
    parentCategory ? handleCategories() : setLoading(false);
  }, [type]);

  return (
    <>
      <div className="bg-gray-100 px-6 pt-2 pb-5 rounded-lg">
        {loading ? (
          <div className="w-full h-[100px] flex justify-center items-center">
            <Loader color="#426286" />
          </div>
        ) : (
          <>
            <AddIssueWithButton type={type} level={0} />

            {clientsIssues?.filter((e) => e?.parent === parentId)?.length >
              0 && (
              <ExpendCloseBtn
                setExpendService={setExpendService}
                expendService={expendService}
                text={"View Pre-fills"}
                isActive={expendService}
              />
            )}

            <div
              className={`flex flex-wrap gap-2 my-5 ${
                expendService ? "h-fit block" : "h-0 hidden"
              }`}
            >
              {clientsIssues
                ?.filter((e) => e?.parent === parentId)
                ?.map((issue) => (
                  <>
                    {issue?.name && (
                      <IssueButton
                        key={issue?.name}
                        label={issue?.name}
                        isActive={
                          newdata?.[type]?.subItems?.[issue?.name]?.isActive
                        }
                        onClick={() =>
                          handleIssueClick(
                            issue,
                            newdata?.[type]?.subItems?.[issue?.name]?.isActive
                          )
                        }
                      />
                    )}
                  </>
                ))}
            </div>

            <div className="w-full flex flex-col-reverse">
              {Object.keys(newdata[type]?.subItems ?? {})
                ?.filter(
                  (e) =>
                    newdata[type]?.subItems &&
                    !Array.isArray(newdata[type]?.subItems?.[e]) &&
                    newdata[type]?.subItems?.[e]?.isActive
                )
                ?.map((issue) => (
                  <SubIssuesComp
                    key={issue}
                    type={issue}
                    parentId={clientsIssues?.find((e) => e?.name === issue)}
                    handleSubCategories={handleSubCategories}
                    level={1}
                    expected={expendedItems?.includes(issue)}
                  />
                ))}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default memo(HvacSalesIssues);
