import { styled } from "styled-components";
import { MdDragIndicator } from "react-icons/md";
import { RiDeleteBinLine } from "react-icons/ri";
import { FaRegEdit } from "react-icons/fa";
import { AiOutlineCloseSquare } from "react-icons/ai";
import { FaArrowsRotate } from "react-icons/fa6";
import { useSortable } from "@dnd-kit/sortable";
import { useEffect, useRef } from "react";

export const SectionTab = ({
  ele,
  index,
  handleToggle,
  handleSectionInput,
}) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({
      id: ele.id,
    });

  const addInputRef = useRef(null);

  useEffect(() => {
    addInputRef?.current && addInputRef?.current?.focus();
  }, []);

  return (
    <SectionsTabs
      active={ele?.isActive}
      edit={ele?.isOpen}
      title={ele?.title}
      ref={setNodeRef}
      style={{
        transform: transform ? `translateY(${transform.y}px)` : undefined,
        transition,
      }}
    >
      <div>
        <i className="drag" {...listeners} ref={setNodeRef}>
          <MdDragIndicator size={"24px"} />
        </i>
        {ele?.title ? (
          <div className="main">
            <p>{ele?.title}</p>
            {ele?.isActive && (
              <button
                className="edit"
                onClick={() => handleToggle(index, "isOpen")}
              >
                {ele?.isOpen ? (
                  <>
                    <AiOutlineCloseSquare /> Cancel
                  </>
                ) : (
                  <>
                    <FaRegEdit /> Edit
                  </>
                )}
              </button>
            )}
          </div>
        ) : (
          <InputStyle
            active={ele?.isActive}
            ref={addInputRef}
            disabled={!ele?.isActive}
            value={ele?.description}
            placeholder="Describe the new section"
            onChange={(e) => {
              handleSectionInput(e?.target?.value, index);
              addInputRef.current = null;
            }}
          />
        )}
        <i
          className="delete"
          onClick={() => !ele?.isOpen && handleToggle(index, "isActive")}
        >
          {ele?.isActive ? (
            <RiDeleteBinLine size={"24px"} />
          ) : (
            <FaArrowsRotate size={"20px"} />
          )}
        </i>
      </div>

      {ele?.title && ele?.isOpen && (
        <InputStyle
          autoFocus
          placeholder="How to adjust this section"
          value={ele?.description}
          onChange={(e) => handleSectionInput(e?.target?.value, index)}
        />
      )}
    </SectionsTabs>
  );
};

const InputStyle = styled.input`
  width: 100%;
  padding: 10px 20px;
  border-radius: 8px;
  margin: 0;
  border: 1px solid #818181;
`;

const SectionsTabs = styled.div`
  background: ${({ active }) => (active ? "#f6f6f6ba" : "#d8d8d8")};
  padding: ${({ title }) => (title ? "14px 10px" : "8px 10px")};
  border-radius: 5px;
  box-shadow: 1px 2px 5px #d0d0d0;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 0 5px;

  > div {
    display: flex;
    gap: 20px;
    align-items: center;
  }

  .drag {
    cursor: move;
    color: ${({ active }) => (active ? "#000000" : "gray")};
  }

  .main {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 20px;
    height: 30px;

    > p {
      font-size: 14px;
      font-weight: 600;
      color: ${({ active }) => (active ? "#000000" : "gray")};
      text-decoration: ${({ active }) => (active ? "auto" : "line-through")};
    }

    .edit {
      padding: 4px 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5px;
      color: ${({ edit }) => (edit ? "gray" : "#ffffff")};
      background: ${({ edit }) => (edit ? "transparent" : "#000000")};
      border-radius: 5px;
      cursor: pointer;
    }
  }

  .delete {
    cursor: ${({ edit }) => (edit ? "no-drop" : "pointer")};
    color: ${({ active }) => (active ? "#000000" : "gray")};
  }
`;
